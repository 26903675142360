<template>
    <div>
        <div class="chatlist">
            <div v-for="(item) of message" :key="item.id||item.guid">
                <template>
                    <p class="time" v-if="item.date">
                        <format-time :time="item.date"></format-time>
                    </p>
                    <div :class="{chatout:from===item.from,chatin:from!==item.from}">
                        <span class="username">{{item.from}}</span>
                        <img class="face" v-if="from===item.from" src="/static/manage/images/avatar/me.png">
                        <img v-else src="/static/manage/images/avatar/base.png">
                        <span class="content">
                            <slot :item="item"></slot>
                        </span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import formatTime from '@/components/common/format-time';
    export default {
        name: "message",
        components: {
            formatTime,
        },
        props: {
            message: {
                require: true,
                type: Array,
            },
            from: {
                require: true
            },
        },
        data:function(){
            return {

            }
        },
        methods:{
        }
    }
</script>

<style scoped lang="less">
    .chatlist {
        margin: 20px;
        padding: 12px;

        p.time {
            margin: 0;

            text-align: center;

            span {
                padding: 5px 18px;
                display: inline-block;
                font-size: 9pt;
                color: #fff;
                border-radius: 2px;
                background-color: #dcdcdc;
            }
        }

        .chatout, .chatin {
            position: relative;
            margin: 10px 0;
        }
        .chatout {
            text-align: right;
            img {
                float: right;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin: 0 0 0 10px;
            }
            .content {
                display: inline-block;
                position: relative;
                padding: 10px;
                max-width: calc(100% - 90px);
                min-height: 40px;
                line-height: 20px;
                font-size: 13px;
                word-break: break-all;
                border-radius: 4px;
                background-color: #66a6ff;
                color: #fff;
                &:before {
                    content: " ";
                    position: absolute;
                    top: 9px;
                    left: 100%;
                    border: 6px solid transparent;
                    border-left-color: #66a6ff;
                }
            }
        }
        .chatin {
            .username{
                position: absolute;
                font-size: 12px;
                left: 35px;
                color: #b3b1b1;
            }
            .face {
                float: left;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin: 0 10px 0 0;
            }
            .content {
                margin-top: 18px;
                margin-left: 5px;
                display: inline-block;
                position: relative;
                padding: 10px;
                max-width: calc(100% - 90px);
                min-height: 40px;
                line-height: 20px;
                font-size: 13px;
                word-break: break-all;
                border-radius: 4px;
                text-align: left;
                background-color: #a6c1ee;
                &:before {
                    content: " ";
                    position: absolute;
                    top: 9px;
                    right: 100%;
                    border: 6px solid transparent;
                    border-right-color: #a6c1ee;
                }
            }
        }
    }
</style>
