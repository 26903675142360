<template>
    <div class="body">
        <div class="chart">
            <el-tabs v-model="currentPanel" type="border-card">
                <el-tab-pane name="file" label="我的文件">
                    <div class="data-area">
                        <div class="mb-3">
                            <search  @condition="condition" :fields="searchFields">
                                <el-button type="primary" size="medium">buttonCont</el-button>
                            </search>
                        </div>
                        <div>
                            <el-table
                                    :data="tableData"
                                    stripe
                                    border
                                    :default-sort="page.sort"
                                    style="width: 100%">
                                <el-table-column v-for="item in showFields"
                                                 :prop="item.name"
                                                 :key="item.name"
                                                 :label="item.title"
                                                 :sortable="item.sortable"
                                                 :width="item.width">
                                    <template slot-scope="scope">
                                        <el-tag v-if="item.name==='platform'" :type="scope.row.platform==='IOS'?'danger':'primary'">
                                            {{scope.row.platform}}
                                        </el-tag>
                                        <span v-else-if="item.name==='filesize'">
                                            {{scope.row.filesize|getSize}}
                                        </span>
                                        <span v-else-if="item.name==='date'">
                                            <format-time :time="scope.row.date" format="Y-m-d H:i:s"></format-time>
                                        </span>
                                        <span v-else>{{scope.row[item.name]}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        label="操作"
                                >
                                    <template slot-scope="scope">
                                        <el-link icon="el-icon-download" class="mr-3" :href="'//api.bigemap.com/manage/mail/attach/'+$route.params.phone+'/'+scope.row.id" target="_blank"></el-link>
                                        <el-link type="danger" icon="el-icon-delete"></el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="send" label="接收记录">
                    <div class="data-area">
                        <div class="pt-3">
                            <search :init="false" @condition="condition" :data="searchDefault" :fields="searchAllEmail">
                            </search>
                        </div>
                        <div>
                            <message :from="$route.params.phone" :message="emails"></message>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="chart" label="最近聊天">
                    <div class="data-area"></div>

                </el-tab-pane>
                <el-tab-pane name="friend" label="好友">
                    <div class="data-area">

                    </div>
                </el-tab-pane>
                <el-tab-pane name="login" label="登陆信息">
                    <div class="data-area">

                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="people-list">

            </div>
            <div class="detail">

            </div>
            <div class="people-group">

            </div>
        </div>
    </div>
</template>
<script>
    import F from "@/fields/fields";
    import Search from "@/components/common/search";
    import Message  from "@/components/chart/message";
    import FormatTime from '@/components/common/format-time';
    export default {
        name: "chart",
        components: {
            Search,Message
            ,FormatTime
        },
        data: function () {
            return {
                currentPanel: 'file',
                searchDefault: {
                    date: [new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(), new Date().getTime()],
                },
                page: {
                    search: {},
                    sort: {prop: 'date', order: 'descending'},
                    total: 1,
                    pageSize: 10,
                    pageNumber: 1,
                },
                tableData: [],
                emails:[],
            }
        },
        filters: {
            getSize: function (size) {
                size = parseInt(size);
                if (!size || isNaN(size)) return '未知';
                let index = 0;
                for (let i = 0; i <= 10; i++) {
                    index = i;
                    if (size < 1024) break;
                    size = size / 1024;
                }
                let extension = ['B', 'KB', 'M', 'GB'];
                return size.toFixed(2) + extension[index];
            }
        },
        computed: {
            searchFields: function () {
                return F.search('email', 'search');
            },
            searchAllEmail: function () {
                return F.search('email', 'allSearch');
            },
            showFields: function () {
                return F.show('email', 'show');
            }
        },
        methods: {
            sortChange: function (column) {
                if (!column.order) return;
                this.page.sort = {prop: column.prop, order: column.order};
                this.search();
            },
            condition: function (search) {
                this.page.search = search;
                this.page.pageNumber = 1;
                this.search();
            },
            search: function () {
                switch (this.currentPanel) {
                    case 'file':
                        this.$axiosApp.get('/manage/mail/attach/' + this.$route.params.phone, {
                            params: this.page,
                        }).then(data => {
                            this.page.total = data.data.total;
                            this.page.pageNumber = data.data.pageNumber;
                            this.page.pageSize = data.data.pageSize;
                            this.tableData = data.data.files;
                        });
                        break;
                        case 'send':
                            this.$axiosApp.get('/manage/mail/email/' + this.$route.params.phone, {
                                params: this.page,
                            }).then(data => {
                                this.page.total = data.data.total;
                                this.page.pageNumber = data.data.pageNumber;
                                this.page.pageSize = data.data.pageSize;
                                this.emails = data.data.emails;
                            });
                            break;
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .body {
        position: relative;
        height: calc(100vh - 80px);
    }

    .chart {
        height: 100%;

        .data-area {
            /*height: calc(100vh - 180px);*/
        }
    }
</style>
